body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: MontSerrat, Helvetica, Arial, sans-serif;
  overflow: hidden;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

/* TODO: Move these into emotion */
.rv-discrete-color-legend-item__color {
  height: 14px;
}

.rv-xy-plot__grid-lines {
  background: rgba(0, 0, 0, 0.5);
}

.rv-discrete-color-legend.horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  margin-right: 0;
  margin-top: -34px;
  width: 100% !important;
}

.rv-discrete-color-legend-item.horizontal {
  display: flex;
}

.rv-discrete-color-legend-item.horizontal
  .rv-discrete-color-legend-item__title {
  padding-left: 10px;
}

.pulsate-bck {
  animation: pulsate-bck 2s ease-in-out infinite both;
}

@keyframes pulsate-bck {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

#hive-spinner-small {
  transform-origin: 7px 7px;
  -moz-animation: 1.2s hive-rotate-right infinite ease-out;
  -webkit-animation: 1.2s hive-rotate-right infinite ease-out;
}

@-moz-keyframes hive-rotate-right {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes hive-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
